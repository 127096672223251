window.addEventListener('load', () => {

    setTimeout(() => {
        let resource = document.createElement('script');
        resource.async = "true";
        resource.src = "https://www.instagram.com/embed.js";
        let script = document.getElementsByTagName('script')[0];
        script.parentNode.insertBefore(resource, script);
    }, 3000)

});